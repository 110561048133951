.fieldErrorContainer {
  position: relative;
  margin-top: 0.5rem;
}

.fieldErrorPointer {
  position: absolute;
  top: -8px;
  left: 10px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #fecaca;
}

.fieldErrorContent {
  display: flex;
  align-items: center;
  background-color: #fecaca;
  border: 1px solid #f87171;
  border-radius: 4px;
  padding: 0.5rem;
}

.icon {
  width: 20px;
  height: 20px;
  margin-right: 0.5rem;
  color: #dc2626;
}

.fieldErrorMessage {
  color: #dc2626;
  font-size: 0.875rem;
}
