.header {
  background-color: #2c5f2d;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  border-bottom: 3px solid #ffd700;
  margin-left: -30px; /* Compensate for layout padding */
  padding-left: calc(30px + 1rem); /* Add spine width to padding */
}

.logo {
  font-size: 1.5rem;
  font-weight: bold;
  cursor: pointer;
  color: #ffd700;
  font-family: 'Mukta Vaani', Arial, Helvetica;
}

.nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.nav li {
  margin-left: 1.5rem;
}

.nav a {
  text-decoration: none;
  color: #fff;
  font-weight: 500;
  transition: color 0.3s ease;
  font-size: 1rem;
}

.nav a:hover {
  color: #d4af37;
}

.menuToggle {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  z-index: 1000;
}

.hamburger,
.hamburger::before,
.hamburger::after {
  content: '';
  display: block;
  background-color: #fff;
  height: 2px;
  width: 25px;
  transition: all 0.3s ease-in-out;
  position: relative;
}

.hamburger::before {
  content: '';
  position: absolute;
  top: -8px;
}

.hamburger::after {
  content: '';
  position: absolute;
  bottom: -8px;
}

.menuToggle.open .hamburger {
  background-color: transparent;
}

.menuToggle.open .hamburger::before {
  transform: translateY(8px) rotate(45deg);
}

.menuToggle.open .hamburger::after {
  transform: translateY(-8px) rotate(-45deg);
}

@media (max-width: 768px) {
  .header {
    padding: 0.5rem 1rem;
  }

  .menuToggle {
    display: block;
  }

  .nav {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #2c5f2d;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
    z-index: 1000;
  }

  .nav.open {
    max-height: calc(100vh - 60px);
    overflow-y: auto;
  }

  .nav ul {
    flex-direction: column;
    padding: 1rem;
  }

  .nav li {
    margin: 0.5rem 0;
    width: 100%;
    text-align: center;
  }

  .nav a {
    display: block;
    padding: 0.75rem 0;
    font-size: 1.1rem;
  }
}
@media (max-width: 480px) {
  .header {
    margin-left: 0;
    padding-left: 1rem;
  }
}
