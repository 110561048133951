.sectionDescription {
  margin-bottom: 1.5rem;
  color: #4b5563;
  line-height: 1.5;
}

.pendingAnalysis {
  padding: 1rem;
  color: #6b7280;
  text-align: center;
  font-style: italic;
}

.analysisContainer {
  margin-top: 1rem;
}

.analysisContent {
  white-space: pre-wrap;
  font-family: monospace;
  background-color: #f3f4f6;
  padding: 1rem;
  border-radius: 0.375rem;
  margin-bottom: 1rem;
}

.noAnalysis {
  text-align: center;
  padding: 2rem;
  color: #6b7280;
}

.analysisButton {
  margin-top: 1rem;
  padding: 0.75rem 1.5rem;
  background-color: #2563eb;
  color: white;
  border: none;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.analysisButton:hover {
  background-color: #1d4ed8;
}

.analysisButton:disabled {
  background-color: #9ca3af;
  cursor: not-allowed;
}
