/* src/components/Layout/Layout.module.css */
.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f0f0e0; /* Light cream background to mimic paper */
  padding-left: 30px; /* Add padding for spine */
  overflow-x: hidden;
  overflow-y: visible;
}

/* Make sure no parent elements clip the overflow */
body {
  overflow-x: hidden;
  overflow-y: visible;
}

html {
  overflow-x: hidden;
  overflow-y: visible;
}

.main {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.1); /* Inner shadow for page effect */
  padding-left: 30px; /* Changed from margin to padding */
  position: relative; /* Add this */
}

.homeMain {
  padding: 0;
  max-width: 100%;
  width: 100%;
}

/* Golden border for non-home pages */
.main:not(.homeMain) {
  border-left: 2px solid #ffd700; /* Gold color */
  border-right: 2px solid #ffd700;
  margin: 0 1rem; /* Add some margin on the sides */
}

.bookSpine {
  width: 30px; /* Increased width to accommodate text */
  background-color: #2c5f2d; /* Deep green */
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  box-shadow: inset -2px 0 5px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  z-index: 100; /* Keep spine above other content */
}

@media (max-width: 768px) {
  .bookSpine {
    width: 20px;
  }

  .main {
    padding-left: 20px;
  }
}

@media (max-width: 480px) {
  .layout {
    padding-left: 0; /* Remove padding on small screens */
  }

  .bookSpine {
    display: none;
    z-index: 0;
  }

  .main {
    padding-left: 0;
  }
}

.spineText {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  transform: rotate(180deg);
  color: #d4af37; /* Golden color */
  font-size: 1.2rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-family: 'Mukta Vaani', Arial, Helvetica;
  cursor: pointer;
}

.publisherLogo {
  width: 24px;
  height: 24px;
  background-color: #d4af37; /* Golden color */
  color: #2c5f2d; /* Deep green */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  text-decoration: none;
  font-size: 0.9rem;
  transition: transform 0.3s ease;
}

.publisherLogo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.publisherLogo:hover {
  transform: scale(1.1);
}

.easterEggTop {
  position: absolute;
  top: -26px; /* Positions it above the viewport */
  right: 20px;
  width: 40px;
  height: 40px;
  pointer-events: none;
  z-index: 1001;
  background: url('/public/images/fellowship.png') center center no-repeat;
  background-size: contain;
  will-change: transform; /* Helps with rendering on some browsers */
}

.easterEggBottom {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  pointer-events: none;
  z-index: -1;
  opacity: 0.2;
  background: url('/public/images/IMP_white.svg') center 200% no-repeat;
  transform: translateY(calc(var(--overscroll-bottom, 0) * 0.5));
}

@media (max-width: 768px) {
  .easterEggTop {
    right: 60px;
  }
}
