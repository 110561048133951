/* // src/components/AdminDashboardLayout.module.css */
.adminDashboard {
  display: flex;
  height: 100vh;
  width: 100%;
}

.sidebar {
  width: 250px;
  background-color: #f0f0f0;
  padding: 20px;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar li {
  margin-bottom: 10px;
}

.content {
  flex-grow: 1;
  padding: 20px;
}
