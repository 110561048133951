.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.logo {
  font-size: 1.5rem;
  font-weight: bold;
}

.nav ul {
  display: flex;
  gap: 1rem;
  list-style-type: none;
}

.nav a {
  color: white;
  text-decoration: none;
}

.nav a:hover {
  color: #fbbf24;
}

.heroSection {
  background-color: #f0f0e0;
  color: #065f46;
  padding: 5rem 0;
  text-align: center;
  border-bottom: 1px solid #ffd700; /* Golden border */
}

.heroTitle {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.heroSubtitle {
  font-size: 1.25rem;
  margin-bottom: 2rem;
}

.button {
  background-color: #fbbf24;
  color: #065f46;
  padding: 0.75rem 1.5rem;
  border-radius: 9999px;
  font-weight: 600;
  text-decoration: none;
  display: inline-block;
  transition: background-color 0.3s;
}

.button:hover {
  background-color: #f59e0b;
}

.section {
  padding: 4rem 0;
  border-bottom: 1px solid #ffd700; /* Golden border */
}

.sectionTitle {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 3rem;
  color: #065f46;
}

.featureGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
}

.featureItem {
  text-align: center;
}

.featureIcon {
  font-size: 3rem;
  color: #059669;
  margin-bottom: 1rem;
}

.featureTitle {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: #065f46;
}

.pricingSection {
  background-color: #f0f0e0;
}

.pricingGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
}

.pricingCard {
  background-color: white;
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.pricingCard.featured {
  border: 2px solid #fbbf24;
}

.pricingTitle {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: #065f46;
}

.pricingPrice {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #059669;
}

.pricingFeatures {
  margin-bottom: 2rem;
  color: #065f46;
}

.testimonialGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.testimonialCard {
  background-color: white;
  padding: 1.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.testimonialText {
  margin-bottom: 1rem;
  color: #065f46;
}

.testimonialAuthor {
  font-weight: 600;
  color: #065f46;
}

.footer {
  background-color: #065f46;
  color: white;
  padding: 2rem 0;
  text-align: center;
}

@media (max-width: 768px) {
  .headerContent {
    flex-direction: column;
    align-items: flex-start;
  }

  .nav {
    margin-top: 1rem;
  }

  .nav ul {
    flex-direction: column;
    gap: 0.5rem;
  }

  .heroTitle {
    font-size: 2rem;
  }

  .heroSubtitle {
    font-size: 1rem;
  }

  .sectionTitle {
    font-size: 1.75rem;
  }

  .featureGrid,
  .pricingGrid,
  .testimonialGrid {
    grid-template-columns: 1fr;
  }
}
